.paragraph-length {
    width: 100%;
}

.management-cards {
    /* min-width: 550px; */
    /* width: calc(50% - 8px); */
    min-height: calc(350px - 8px);
    min-width: 600px;

}

@media screen and (max-width: 1379px) {
    .card-info {
        width: 100%;
        margin-bottom: 16px;
        min-height: 342px;
        max-height: 550px;
    }

    .exception {
        width: 100%;
        display: flex;
        min-width: 600px;
        flex-direction: column;
    }

    .card-structure {
        width: 100%;
        margin-bottom: 16px;
    }
    
    .card-history {
        width: 100%;
        margin-bottom: 16px;
    }

    .card-rules {
        width: 100%;
        margin-bottom: 16px;
        margin-right: 0px;
    }

    .card-admin {
        width: 100%;
    }
}

@media screen and (min-width: 1380px) and (max-width: 1699px) {
    .card-info {
        width: 100%;
        margin-bottom: 16px;
    }
    
    .exception {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;
    }
    
    .card-structure {
        /* width: calc(50% - 8px); */
        /* margin-right: 16px; */
        width: 100%;
    }

    .card-history {
        width: calc(50% - 8px);
    }

    .card-rules {
        margin-right: 16px;
    }
}

@media screen and (min-width: 1700px) {
    .exception {
        width: calc(50% - 8px);
        display: flex;
        flex-direction: column;
    }
    
    .card-info {
        /* min-width: 700px; */
        /* height: 700px; */
        margin-right: 16px;
        margin-bottom: 16px;
        width: calc(50% - 8px);
    }
    
    .card-structure {
        min-width: 550px;
        /* height: calc(350px - 8px); */
        margin-bottom: 16px;
    }
    
    .card-history {
        min-width: 550px;
        height: calc(350px - 8px);
    }
    
    .card-rules {
        margin-right: 16px;
    }
}