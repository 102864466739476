.Infographics-StepCreate-icons-title {
    height: 36px;
    color: #9254de;
    margin-top: 8px;
    margin-bottom: 1rem;
}

.Infographics-StepCreate-icons-anchor {
    height: 36px;
    color: #9254de;
    margin-bottom: 1rem;
}

.carousel-arrows-btn {
    position: absolute;
    top: 155;
    font-size: 24px;
}

.vis-selector > .ant-tabs-tab-active > .ant-tabs-tab-btn {
    color: #faad14 !important;
}

.row-dragging {
    z-index: 9999;
    width: 768px;
    background-color: #f9f0ff;
    display: flex;
    flex-direction: row;
}

.row-dragging > * {
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.row-dragging:first-child {
    width: 55px;
}

.row-dragging > *:not(:first-child) {
    width: 348px;
}

.row-dragging > *:last-child {
    width: 370px;
}