/* LIST HEADER */
.project-list-header {
    min-width: 10px;
    margin-bottom: 16px;
}

.projects-input {
    width: 50%;
    min-width: 450px;
}

.filters-groups {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.project-title {
    font-weight: bold;
    padding: 0 !important;
    /* color: #9254de; */
}

.ant-divider-horizontal {
    margin: 10px 0px !important;
}

.project-mini-display {
    width: 100%;
}
