/* .HomeIndicators {
    
} */

.HomeIndicators-input {
    width: 45vw;
    min-width: 350px;
}

/* .HomeIndicators-results {
    overflow-y: hidden;
} */

.HomeIndicators-card {
    min-width: 350px;
    /* width: 100%; */
    /* width: 30vw; */
    /* max-width: 500px; */
    margin-top: .3rem;
}

.HomeIndicators-card:hover {
    box-shadow: 0 4px 10px 0 rgb(20 19 34 / 3%), 0 0 10px 0 rgb(20 19 34 / 2%);
}

.HomeIndicators-card-cover {
    width: 100%;
    height: 50px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.HomeIndicators-card-content {
    min-height: 100px;
    overflow-y: scroll;
}

.HomeIndicators-progress {
    padding-top: 5px;
}