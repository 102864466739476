.logo-container {
    display: flex;
    height: 60px;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #141322;
}

.auth-header {
    height: 3.7rem;
    border-bottom: 1px solid #e4e9f0;
    background-color: #ffffff;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 1.86rem;
    padding-left: 1.86rem;
    width: 100%;
}

.auth-body {
    padding-top: 8rem;
    background-color: #ECEFF6;
    height: calc(100% - 3.7rem);
    width: 100%;
}

.auth-wrapper {
    margin-left: auto! important;
    margin-right: auto !important;
    margin-top: 3rem;
    max-width: 30rem;
    min-width: 30rem;
    padding: 0.66rem;
    box-sizing: border-box;
}

.card {
    margin-bottom: 2rem;
    border-color: #e4e9f0;
    word-wrap: break-word;
    box-shadow: 0 4px 10px 0 rgb(20 19 34 / 3%), 0 0 10px 0 rgb(20 19 34 / 2%);
}

.auth-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #141322;
}

.auth-remember > * > .ant-checkbox-inner {
    border-radius: 3px;
}

.auth-btn-login {
    width: 100%;
}

.auth-forgotten {
    margin-top: 1.5rem;
    width: 100%;
    color: #22075e;
}