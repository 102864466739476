/* SAFARI ICON RESIZE */

.anticon > * {
    max-width: 30px;
    max-height: 30px;
}

#set-favorite {
    &,
    & * {
        > .anticon > * {
            color: #faad14;
            width: 20px;
            height: 20px;
        }
    }
}

#app-menu {
    > .anticon > *,
    * > .anticon > * {
        max-width: 14px;
        max-height: 14px;
    }
}

#app-header > .anticon > * {
    max-width: 24px;
    max-height: 24px;
}

#inside-tag {
    > .anticon > *,
    * > .anticon > * {
        max-width: 12px;
        max-height: 12px;
    }
}

.ant-card-actions {
    > .anticon > *,
    * > .anticon > * {
        max-width: 16px;
        max-height: 16px;
    }
}

.ant-alert > .anticon > * {
    max-width: 20px;
    max-height: 20px;
}

.ant-form-item-control-input-content * > .anticon > * {
    max-width: 14px;
    max-height: 14px;
}

.ant-select-selection-item-remove > .anticon > svg {
    max-width: 10px;
    max-height: 10px;
}

.ant-select-item-option-state > .anticon > svg {
    max-width: 14px;
    max-height: 14px;
}

.ant-table {
    > .anticon > *,
    * > .anticon > * {
        max-width: 25px;
        max-height: 25px;
    }
}