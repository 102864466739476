.UserMenu-avatar {
    font-size: 16px;
    min-width: 40px;
    display: flex;
    align-items: center;
}

.UserMenu-fullname {
    margin: 10px;
    font-size: 14px;
}

.UserMenu-submenu > div:first-child {
    height: 92px !important;
    display: flex;
    align-items: center;
    padding-left: 21px !important;
}

.UserMenu-menu-item {
    /* background-color: #f0f2f5; */
    font-size: 13px;
}
