.gedata-root {
  contain: none;
  @import "./antd.min.css";
  @import "./_constants.scss";
  @import "./fixes/safari_icons.scss";
  // body {
  // margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  .project {
    .ant-upload.ant-upload-select-picture-card {
      height: 250px !important;
      width: 300px !important;
    }
  }

  .width-override-auto {
    width: auto !important;
  }

  .table-row-new {
    background-color: #f6ffed;
  }

  .table-row-edit {
    background-color: #e6f7ff;
  }

  .table-row-deleted {
    background-color: #fff1f0;
  }
}

// .ant-select-item-option-state > .anticon > svg {
//   width: 14px;
//   height: 14px;
// }