.fav-table-text-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

@media screen and (max-width: 1600px) {
	.fav-table-text-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
}

.fav-table-text {
	min-width: 100px;
}

.fav-table-indicateur-title {
	min-width: 220px;
}

.full-logo {
	margin-left: 10px;
}

.mini-logo {
	margin-left: 15px;
}