.NewsTankLayout {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: rgb(240, 240, 240);
}

.NewsTankLayout-body {
    background-color: white;
    width: 1140px;
}

.NewsTankLayout-header {
    height: 215px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.NewsTankLayout-logo {
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.NewsTankLayout-breadcrumbs {
    width: 1030px;
    height: 54px;
    display: flex;
    align-items: center;
    font-weight: bold;

}

.NewsTankLayout-breadcrumbs-separators {
    margin-left: 6px;
    margin-right: 6px;
    color: gray;
}

.NewsTankLayout-iframe {
    height: calc(100vh - 215px);
    width: 1030px;
    padding-top: 12px;
    margin: auto;
    border: none;
    display: flex;
    justify-content: center;
}