.nicer-link {
    color: #22075e;
}

.discrete-link {
    color: #8c8c8c;
}

.cross-button {
    font-weight: bold;
}

.discrete-button {
    background-color: #8c8c8c;
    border-color: #8c8c8c;
}